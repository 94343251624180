  <template>
    <div>
      <v-card class="pa-2">
        <v-row no-gutters>
          <v-col>
            <div class="header-wrapper">
              <div class="date-wrapper d-flex">
                <div class="date-toggle">
                  <v-btn small depressed tile :class="{ 'active-btn': allDates === false ? true : false }" width="100%"
                    @click="changeDate(false)">Vandaag</v-btn>
                  <v-btn small depressed tile :class="{ 'active-btn': allDates === true ? true : false }" width="100%"
                    @click="changeDate(true)">Alles</v-btn>
                  <v-btn small depressed tile :class="{ 'active-btn': allDates === -1 ? true : false }" width="100%"
                    @click="changeDate(-1)">Datum</v-btn>
                </div>
                <div class="date-picker d-flex align-center">
                  <div class="search-date">
                    <date-picker :outlined="true" :filled="true" :value="fromDate" label="Datum"
                      :dateFormatted="fromDateFormatted" :disabled="allDates !== -1"
                      @changeDate="changeFromDate"></date-picker>
                  </div>
                  <div class="search-date">
                    <date-picker :outlined="true" :filled="true" :value="toDate" label="t/m"
                      :dateFormatted="toDateFormatted" :disabled="allDates !== -1"
                      @changeDate="changeToDate"></date-picker>
                  </div>
                </div>
              </div>
              <div class="header-inner-wrapper">
                <search-client class="search-client" :searchString="searchString" @setSearchString="setSearchString"
                  @get-results="getClientInvoices" @clear-input="clearClient"></search-client>
                <div class="search-invoice">
                  <v-text-field outlined filled type="number" v-model="searchInvoiceNumber"
                    @keydown.enter="fetchInvoice" prepend-inner-icon="mdi-file-search" label="Factuurnummer" clearable
                    hide-details></v-text-field>
                </div>
                <div class="search-imported">
                  <v-checkbox label="Geïmporteerde facturen" v-model="importedInvoices"></v-checkbox>
                </div>
              </div>
              <div>
                <v-row no-gutters>
                  <fieldset class="fieldset pa-4">
                    <legend class="legend">Status</legend>
                    <v-badge overlap offset-y="3px" v-for="(status, index) in statuses" :key="index"
                      :content="status.length > 0 ? status.length : '0'">
                      <v-chip :close="selectedStatus == status.status" :class="{
                        selectedStatus: selectedStatus == status.status
                      }" @click="selectStatus(status.status)" @click:close="selectStatus(-1)" :color="status.color">{{
                        status.text }}</v-chip>
                    </v-badge>
                  </fieldset>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters class="mt-5">
          <v-col>
            <!-- <v-btn color="primary" :disabled="!selectedInvoices.length || !selectedClient._id" -->
            <v-btn color="primary" :disabled="true" @click="openMultiInvoicePaymentModal">Betaling</v-btn>
            <v-btn color="primary" class="ml-2" :disabled="!selectedInvoices.length"
              @click="downloadSelectedInvoices">Download</v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-row>
        <v-col class="d-flex justify-space-around flex-wrap">
          <span class="overview-totals">Excl. : {{ toCurrency(netTotal) }}</span>
          <span class="overview-totals">Incl. : {{ toCurrency(grossTotal) }}</span>
          <span class="overview-totals">Openstaand: {{ toCurrency(debit) }}</span>
        </v-col>
      </v-row>
      <v-row id="sortable" no-gutters>
        <v-col>
          <v-data-table v-if="allDates" v-model="selectedInvoices" :headers="invoiceHeaders" :items="invoices"
            :single-select="singleSelect" item-key="_id" :options.sync="options" @update:options="updateOptions"
            :server-items-length="totalInvoices" :footer-props="{ 'items-per-page-options': [-1, 10, 25, 50, 100] }"
            show-select show-expand :expanded.sync="expanded" class="elevation-1" locale="nl"
            selectable-key="selectable">

            <template v-slot:[`item.date`]="{ item }">{{ toDateString(item.date) }}</template>
            <template v-slot:[`item.client.name`]="{ item }">
              <a @click="openInvoiceModal(item)">{{ item.client.name }}</a>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="openPreviewInvoiceModal(item)">mdi-eye</v-icon>
              <v-icon @click="downloadInvoice(item)">mdi-download</v-icon>
              <v-icon @click="openInvoicePaymentModal(item)">mdi-currency-eur</v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="invoiceHeaders.length + 2">
                <v-data-table disable-pagination hide-default-footer :headers="itemHeaders" :items="item.orders"
                  style="width:100%" class="elevation-1 item-table">
                </v-data-table>
              </td>
            </template>
            <template v-slot:[`item.printed`]="{ item }">
              <a @click="openPrintInvoiceModal(item)">
                <v-icon large v-if="item.printed" color="green">mdi-printer-check</v-icon>
                <v-icon large v-else color="red">mdi-printer</v-icon>
              </a>
            </template>
            <template v-slot:[`item.emailed`]="{ item }">
              <a @click="openEmailInvoiceModal(item)">
                <v-icon large v-if="item.emailed" color="green">mdi-email-check</v-icon>
                <v-icon large v-else color="red">mdi-email</v-icon>
              </a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div>
                <v-chip @click="incInvoice(item)" :color="invoiceStatuses[item.status].color" label>{{
                  invoiceStatuses[item.status].text }}</v-chip>
              </div>
            </template>
            <template v-slot:[`item.grossTotal`]="{ item }">
              <div>{{ toCurrency(item.grossTotal) }}</div>
            </template>
            <template v-slot:[`item.debit`]="{ item }">
              <div>{{ toCurrency(item.debit) }}</div>
            </template>
          </v-data-table>
          <v-data-table v-else v-model="selectedInvoices" :headers="invoiceHeaders" :items="invoices"
            :single-select="singleSelect" item-key="_id" :options.sync="options"
            :footer-props="{ 'items-per-page-options': [-1, 10, 25, 50, 100] }" show-select show-expand
            :expanded.sync="expanded" class="elevation-1" locale="nl" selectable-key="selectable">

            <template v-slot:[`item.date`]="{ item }">{{ toDateString(item.date) }}</template>
            <template v-slot:[`item.client.name`]="{ item }">
              <a @click="openInvoiceModal(item)">{{ item.client.name }}</a>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon @click="openPreviewInvoiceModal(item)">mdi-eye</v-icon>
              <v-icon @click="downloadInvoice(item)">mdi-download</v-icon>
              <v-icon @click="openInvoicePaymentModal(item)">mdi-currency-eur</v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="invoiceHeaders.length + 2">
                <v-data-table disable-pagination hide-default-footer :headers="itemHeaders" :items="item.orders"
                  style="width:100%" class="elevation-1 item-table">
                </v-data-table>
              </td>
            </template>
            <template v-slot:[`item.printed`]="{ item }">
              <a @click="openPrintInvoiceModal(item)">
                <v-icon large v-if="item.printed" color="green">mdi-printer-check</v-icon>
                <v-icon large v-else color="red">mdi-printer</v-icon>
              </a>
            </template>
            <template v-slot:[`item.emailed`]="{ item }">
              <a @click="openEmailInvoiceModal(item)">
                <v-icon large v-if="item.emailed" color="green">mdi-email-check</v-icon>
                <v-icon large v-else color="red">mdi-email</v-icon>
              </a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <div>
                <v-chip @click="incInvoice(item)" :color="invoiceStatuses[item.status].color" label>{{
                  invoiceStatuses[item.status].text }}</v-chip>
              </div>
            </template>
            <template v-slot:[`item.grossTotal`]="{ item }">
              <div>{{ toCurrency(item.grossTotal) }}</div>
            </template>
            <template v-slot:[`item.debit`]="{ item }">
              <div>{{ toCurrency(item.debit) }}</div>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <invoice-modal v-if="invoiceModal" :invoice="JSON.parse(JSON.stringify(invoice))" :invoiceModal="invoiceModal"
        @update-invoice="updateInvoice" @delete-invoice="deleteInvoice"
        @open-print-invoice-modal="openPrintInvoiceModal" @open-email-invoice-modal="openEmailInvoiceModal"
        @close-invoice-modal="closeInvoiceModal"></invoice-modal>
      <print-invoice-modal v-if="printInvoiceModal" :printInvoiceModal="printInvoiceModal" :invoice="invoice"
        @close-print-invoice-modal="closePrintInvoiceModal"></print-invoice-modal>
      <email-invoice-modal v-if="emailInvoiceModal" :emailInvoiceModal="emailInvoiceModal" :invoice="invoice"
        @close-email-invoice-modal="closeEmailInvoiceModal"></email-invoice-modal>
      <invoice-payment-modal v-if="invoicePaymentModal" :invoicePaymentModal="invoicePaymentModal" :invoice="invoice"
        @insert-payments="insertInvoicePayments"
        @close-payment-modal="closeInvoicePaymentModal"></invoice-payment-modal>
      <prompt-modal v-if="promptModal" @confirm="deleteInvoice" @cancel="promptModal = false" promptType="deleteInvoice"
        :data="invoice" :promptModal="promptModal" promptTitle="Verwijder factuur"
        promptText="Weet u zeker dat u deze factuur wilt verwijderen?"></prompt-modal>
      <preview-invoice-modal v-if="previewInvoiceModal" :previewInvoiceModal="previewInvoiceModal" :invoice="invoice"
        @close-preview-invoice-modal="closePreviewInvoiceModal">
      </preview-invoice-modal>
      <multi-invoice-payment-modal v-if="multiInvoicePaymentModal" :multiInvoicePaymentModal="multiInvoicePaymentModal"
        :invoices="selectedInvoices"
        @close-multi-invoice-payment-modal="closeMultiInvoicePaymentModal"></multi-invoice-payment-modal>
    </div>
  </template>
<script>
import searchClient from "../components/searchClient"
import datePicker from "../components/datePicker"
import invoicePaymentModal from "../components/invoicePaymentModal"
import invoiceModal from "../components/invoiceModal"
import printInvoiceModal from "../components/printInvoiceModal"
import emailInvoiceModal from "../components/emailInvoiceModal"
import promptModal from "../components/promptModal"
import previewInvoiceModal from "../components/previewInvoiceModal"
import multiInvoicePaymentModal from '@/components/multiInvoicePaymentModal.vue'
import { errorHandler, fetchPOST, successHandler, toCurrency, toDateString } from "../js/functions"
import {
  downloadInvoice,
} from "../js/pdfController"
/* eslint-env es6 */
export default {
  name: "invoices",
  data() {
    return {
      mounted: false,
      allDates: false,
      promptModal: false,
      fromDate: new Date(Date.now()).toISOString().split("T")[0],
      toDate: new Date(Date.now()).toISOString().split("T")[0],
      fromDateFormatted: this.formatDate(
        new Date().toISOString().split("T")[0]
      ),
      toDateFormatted: this.formatDate(new Date().toISOString().split("T")[0]),
      fromDatePicker: false,
      toDatePicker: false,
      invoiceModal: false,
      printInvoiceModal: false,
      invoiceHeaders: [
        { text: "Status", value: "status", width: 150 },
        { text: "Factuurnr", value: "invoiceNumber" },
        { text: "Naam", value: "client.name" },
        { text: "Datum", value: "date" },
        { text: "Stad", value: "client.city" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Openstaand", value: "debit" },
        { text: "Print", value: "printed", width: 50 },
        { text: "Email", value: "emailed", width: 50 },
        { text: "", value: "actions", width: 130 },
        { text: "", value: "data-table-expand" }
      ],
      itemHeaders: [
        { text: "Status", value: "status" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Totaal", value: "netTotal" },
        { text: "Openstaand", value: "debit" }
      ],
      viewInvoiceModal: false,
      emailInvoiceModal: false,
      invoicePaymentModal: false,
      singleSelect: false,
      selectedInvoices: [],
      options: {
        itemsPerPage: 50,
        page: 1,
        sortBy: ["invoiceNumber"],
        sortDesc: [true],
        qrcode: false
      },
      invoicesLength: 0,
      invoice: {},
      invoiceStatuses: [
        {
          status: 0,
          icon: "mdi-checkbox-blank-circle",
          color: "red",
          text: "Nieuw",
          length: 0
        },
        {
          status: 1,
          icon: "mdi-checkbox-blank-circle",
          color: "orange",
          text: "Openstaand",
          length: 0
        },
        {
          status: 2,
          icon: "mdi-checkbox-marked-circle",
          color: "light-green",
          text: "Betaald",
          length: 0
        }
      ],
      invoiceNetTotal: 0,
      invoiceGrossTotal: 0,
      invoiceDebit: 0,
      thisItem: { name: "", amount: 0 },
      itemCopy: { name: "", amount: 0 },
      searchResults: false,
      tv: false,
      expand: false,
      expanded: [],
      selectedStatus: -1,
      searchInvoiceNumber: "",
      searchClient: false,
      searchString: "",
      importedInvoices: false,
      selectedRoute: "",
      dataUrl: "",
      previewInvoiceModal: false,
      multiInvoicePaymentModal: false
    }
  },
  methods: {
    async changeDate(allDates) {
      this.allDates = allDates === undefined ? this.allDates : allDates
      this.fetchInvoices()
    },
    changeFromDate(date) {
      this.fromDate = date
      this.fromDateFormatted = this.formatDate(date)
      if (this.fromDate > this.toDate) {
        this.toDate = this.fromDate
        this.toDateFormatted = this.fromDateFormatted
      }
      this.changeDate()
    },
    changeToDate(date) {
      this.toDate = date
      this.toDateFormatted = this.formatDate(date)
      if (this.toDate < this.fromDate) {
        this.fromDate = this.toDate
        this.fromDateFormatted = this.toDateFormatted
      }
      this.changeDate()
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split("-")
      return `${day}-${month}-${year}`
    },
    parseDate(date) {
      if (!date) return null
      const [day, month, year] = date.split("-")
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    updateOptions() {
      if (this.allDates !== false) this.fetchInvoices()
    },
    closePrintInvoiceModal() {
      this.printInvoiceModal = false
    },
    openPromptModal(invoice) {
      this.invoice = invoice
      this.promptModal = true
    },
    insertInvoicePayments(payments) {
      this.invoice.payments = payments
      let totalPaid = payments.reduce((acc, cur) => (acc * 100 + cur.amount * 100) / 100, 0)
      this.invoice.credit = totalPaid
      this.invoice.debit = (this.invoice.grossTotal * 100 - totalPaid * 100) / 100
      this.invoice.status = this.invoice.debit == 0 ? 2 : 1
      this.updateInvoice(this.invoice)
      this.invoicePaymentModal = false
    },
    pickItem(item) {
      let data = {
        item: item,
        route: this.selectedRoute,
        date: this.date
      }
      this.$store.commit("assignItem", data)
      this.$store.state.wss.send(
        JSON.stringify({ event: "pickItem", data: data })
      )
    },
    pickInvoice(invoice) {
      if (invoice.assigned && invoice.status == 0) {
        invoice.status = 1
        this.$store.commit("incInvoice", invoice)
      }
      this.$store.commit("assignInvoice", invoice)
      this.$store.state.wss.send(
        JSON.stringify({ event: "pickInvoice", data: invoice })
      )
    },
    downloadInvoice(invoice) {
      downloadInvoice(invoice, this.options)
    },
    downloadSelectedInvoices() {
      for (const invoice of this.selectedInvoices) {
        this.downloadInvoice(invoice)
      }
    },
    openPreviewInvoiceModal(invoice) {
      this.invoice = invoice
      this.previewInvoiceModal = true
    },
    closePreviewInvoiceModal() {
      this.invoice = {}
      this.previewInvoiceModal = false
    },
    fetchInvoice() {
      this.$store.dispatch("fetchGET", {
        type: "fetchInvoices", data: {
          invoiceNumber: this.searchInvoiceNumber
        }
      })
    },
    async fetchInvoices() {
      let data = {
        clientNumber: this.selectedClient.clientNumber,
        category: this.selectedCategory,
        route: this.selectedRoute,
        fromDate: this.fromDate,
        toDate: this.toDate,
        allDates: this.allDates,
        status: this.selectedStatus,
        options: this.options
      }
      try {
        let response = await this.$store.dispatch("fetchGET", { type: "fetchInvoices", data: data })
        this.invoicesLength = response[0].length.length ? response[0].length[0].length : 0
        if (this.allDates) {
          let minStatus = 0
          let foundStatus = false
          for (let status of this.invoiceStatuses) {
            status.length = 0
            if (response[0].statuses && response[0].statuses.length) {
              let index = response[0].statuses.findIndex(i => i._id == status.status)
              if (index > -1) {
                if (!foundStatus && this.selectMinStatus) {
                  minStatus = status.status
                  foundStatus = true
                  if (minStatus < this.selectedStatus) this.selectStatus(minStatus)
                }
                status.length = response[0].statuses[index].length
              }
            }
          }
          this.invoiceNetTotal = response[0].totals[0] ? response[0].totals[0].netTotal : 0
          this.invoiceGrossTotal = response[0].totals[0] ? response[0].totals[0].grossTotal : 0
          this.invoiceDebit = response[0].totals[0] ? response[0].totals[0].debit : 0
        }
      } catch (e) {
        errorHandler(e, "Kan facturen niet ophalen")
      }
    },
    setSearchString(val) {
      this.searchString = val
    },
    getResults(results) {
      this.searchResults = results
    },
    getClientInvoices(val) {
      this.allDates = true
      this.$store.commit("selectClient", val)
      this.fetchInvoices()
    },
    clearClient() {
      this.$store.commit("selectClient", {})
      this.selectMinStatus = false
      this.searchResults = false
      this.searchString = ""
      this.allDates = false
      this.selectedStatus = -1
      this.selectedClient.clientNumber = null
      this.fromDate = new Date().toISOString().split("T")[0]
      this.fromDateFormatted = this.formatDate(this.fromDate)
      this.toDate = new Date().toISOString().split("T")[0]
      this.toDateFormatted = this.formatDate(this.toDate)
      this.allDates = false
      this.fetchInvoices()
    },
    selectStatus(val) {
      this.selectedStatus = val
      this.selectMinStatus = false
      this.options.page = 1
      if (this.allDates) this.fetchInvoices()
    },
    openInvoiceModal(invoice) {
      this.invoice = invoice
      this.invoiceModal = true
    },
    closeInvoiceModal() {
      this.invoiceModal = false
    },
    updateInvoice(invoice) {
      try {
        // if (!ignore) this.invoiceStatus(invoice)
        this.$store.dispatch("fetchPOST", { type: "updateInvoice", data: invoice })
        this.$store.commit("updateInvoice", invoice)
        successHandler("Factuur gewijzigd")
      } catch (e) {
        errorHandler(e, "Factuur niet gewijzigd")
      }
    },
    deleteInvoice() {
      try {
        this.$store.dispatch("fetchPOST", { type: "deleteInvoice", data: { _id: this.invoice._id } })
        successHandler("Factuur verwijderd")
        this.invoice = {}
        this.promptModal = false
      } catch (e) {
        errorHandler(e, "Factuur niet verwijderd")
      }
    },
    openPrintInvoiceModal(invoice) {
      this.invoice = invoice
      this.printInvoiceModal = true
    },
    openEmailInvoiceModal(invoice) {
      this.invoice = invoice
      this.emailInvoiceModal = true
    },
    closeEmailInvoiceModal() {
      this.invoice = {}
      this.emailInvoiceModal = false
    },
    toggleAll() {
      for (let invoice of this.invoices) {
        invoice.expand = this.expand ? false : true
      }
      this.expand = !this.expand
    },
    toggleExpand() {
      this.expand = !this.expand
      this.expand
        ? this.invoices.forEach(invoice => this.expanded.push(invoice))
        : (this.expanded = [])
    },
    toggleCollapse(index, item) {
      item.expand = !item.expand
    },
    toggleView(invoice) {
      invoice.expand = !invoice.expand
    },

    async incInvoice(invoice) {
      try {
        if (invoice.status < 1) invoice.status++
        let res = await fetchPOST("incInvoice", invoice)
        if (res.result.nModified == 0) throw "incInvoice"
        this.$store.commit("incInvoice", invoice)
      } catch (e) {
        errorHandler(e, "Factuur niet gewijzigd")
      }
    },
    orderStatus(invoice) {
      let arr = []
      let len = invoice.items.length
      let i = 0
      let status = 0
      for (i; i < len; i++) {
        arr.push(invoice.items[i].status)
      }
      let min = Math.min(...arr)
      let max = Math.max(...arr)
      if (max > 1 && min < 2) status = 1
      else status = min
      if (invoice.status === status) return status
      if (!(invoice.status >= 2)) invoice.status = status
      this.$store.dispatch("fetchPOST", { type: "orderStatus", data: invoice })
      return status
    },
    findInvoice(id) {
      for (let invoice of this.invoices) {
        if (invoice._id === id) {
          return invoice
        }
      }
    },
    completeItem(item) {
      let confirmed = confirm("" + item.name + " voltooien?")
      if (confirmed) {
        let data = {
          item: item,
          route: this.selectedRoute,
          date: this.date
        }
        this.$store.commit("completeItem", data)
        this.$store.state.wss.send(
          JSON.stringify({ event: "completeItem", data: data })
        )
      }
    },
    isComplete(data) {
      if (data > 2) {
        return true
      } else {
        return false
      }
    },
    toCurrency(val) {
      return toCurrency(val)
    },
    toDateString(val) {
      return toDateString(val)
    },
    setTv() {
      this.tv = !this.tv
    },
    openInvoicePaymentModal(invoice) {
      this.invoice = invoice
      this.invoicePaymentModal = true
    },
    closeInvoicePaymentModal() {
      this.invoice = {}
      this.invoicePaymentModal = false
    },
    openMultiInvoicePaymentModal() {
      this.multiInvoicePaymentModal = true
    },
    closeMultiInvoicePaymentModal() {
      this.multiInvoicePaymentModal = false
    }
  },
  computed: {
    selectedClient() {
      return this.$store.state.selectedClient
    },
    invoices() {
      // if (this.searchResults) return this.searchResults
      let data = this.$store.state.invoices
      let till = this.selectedStatus == -1 ? 4 : this.selectedStatus + 1
      let from = this.selectedStatus == 0 ? -1 : this.selectedStatus - 1
      let invoices = data.filter(
        invoice => invoice.status > from && invoice.status < till
      )
      return invoices
    },
    // computedDateFormatted() {
    //   return this.formatDate(this.date)
    // },
    selectedCategory() {
      return this.$store.state.selectedCategory
    },
    statuses() {
      let statuses = this.invoiceStatuses
      console.log("statuses")
      if (this.mounted && !this.allDates) {
        let invoices = this.$store.state.invoices
        statuses.forEach(status => status.length = invoices.filter(invoice => invoice.status == status.status).length)
      }
      return statuses
    },
    totalInvoices() {
      let totalInvoices = this.allDates && this.selectedStatus > -1 ? this.statuses[this.selectedStatus].length : this.invoicesLength
      return totalInvoices
    },
    netTotal() {
      let netTotal = 0
      if (this.allDates) netTotal = this.invoiceNetTotal
      if (!this.allDates) netTotal = this.invoices.reduce((acc, cur) => acc + cur.netTotal, 0)
      if (this.selectedInvoices.length) netTotal = this.selectedInvoices.reduce((acc, cur) => acc + cur.netTotal, 0)
      return netTotal
    },
    grossTotal() {
      let grossTotal = 0
      if (this.allDates) grossTotal = this.invoiceGrossTotal
      if (!this.allDates) grossTotal = this.invoices.reduce((acc, cur) => acc + cur.grossTotal, 0)
      if (this.selectedInvoices.length) grossTotal = this.selectedInvoices.reduce((acc, cur) => acc + cur.grossTotal, 0)
      return grossTotal
    },
    debit() {
      let debit = 0
      if (this.allDates) debit = this.invoiceDebit
      if (!this.allDates) debit = this.invoices.reduce((acc, cur) => acc + cur.debit, 0)
      if (this.selectedInvoices.length) debit = this.selectedInvoices.reduce((acc, cur) => acc + cur.debit, 0)
      return debit
    }
  },
  mounted() {
    this.mounted = true
    if (this.selectedClient.clientNumber) {
      this.searchString = this.selectedClient.name
      this.allDates = true
      this.selectedStatus = 2
      this.selectMinStatus = true
    }
    this.fetchInvoices()
    this.options.qrcode = this.$store.state.settings.ideal.enabled ? true : false
  },
  components: {
    searchClient,
    datePicker,
    invoiceModal,
    printInvoiceModal,
    emailInvoiceModal,
    invoicePaymentModal,
    promptModal,
    previewInvoiceModal,
    multiInvoicePaymentModal
  }
}
</script>
<style scoped>
#autocomplete-results {
  display: none;
}

.invoice {
  text-align: left;
  text-shadow: none;
  background: ghostwhite;
  color: black;
  border: 5px solid rgb(226, 226, 226);
  margin: 10px;
  float: left;
  width: 225px;
  border-radius: 5px;
}

.item-table {
  background: #eeeeee;
}

.statuses {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
  vertical-align: middle;
}

.invoice th,
.invoice th a {
  color: #c41e3a;
  font-weight: bold;
}

.invoices {
  text-decoration: none;
  color: black;
  padding: 5px;
}

.invoice-list {
  text-align: left;
  background: #f8f8ff;
  border-collapse: collapse;
  cursor: pointer;
}

.invoice-list th,
.invoice-list td {
  padding: 10px;
}

.invoice-list td:first-child {
  font-weight: bold;
}

.amount {
  width: 40px;
  height: 25px;
}

.item {
  width: 130px;
  height: 25px;
}

.qty {
  font-size: 0.8em;
  margin-left: -5px;
}

.priority {
  animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.sendAgain {
  margin-left: 10px;
  width: 20px;
}

.statusImage {
  height: 20px;
  padding: 2px;
}

.totals {
  font-size: 30px;
  text-align: left;
  margin: 0 50px 0 0;
  border-collapse: collapse;
  break-inside: avoid;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
}

.columns {
  column-count: 2;
}

.borders {
  border: 1px solid black;
}

.totals td {
  padding-right: 10px;
  width: 110px;
}

.totals td:nth-child(1) {
  width: 200px;
}

.totals td:nth-child(2) {
  font-weight: 900;
  font-size: 50px;
}

.crates {
  position: relative;
}

.crates span span {
  position: absolute;
  top: 0;
  right: 10px;
}

.statusBar {
  width: 60px;
}

.hide {
  display: none;
}

.show {
  display: table-row;
}

.arrow {
  width: 25px;
}

.actions {
  width: 30px;
  padding: 5px;
  vertical-align: super;
}

.clearBoth {
  clear: both;
}

.alignLeft {
  text-align: left;
}

.alignRight {
  text-align: right;
}

.selectedStatus {
  font-weight: bold;
  border: 5px solid #013FBA;
}

.v-application .red,
.v-application .orange,
.v-application .light-green {
  color: white;
  border-color: #013FBA !important;
}

::v-deep .v-badge__badge {
  color: white !important;
}

.overview-totals {
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
}

.header-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.header-wrapper .date-wrapper .date-toggle {
  min-width: 100px;
  max-width: 100px;
  border: 1px solid #00000061;
  border-radius: 5px;
}

.header-wrapper .header-inner-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.header-wrapper .header-inner-wrapper div {
  margin-right: 5px;
}

.header-wrapper .date-wrapper .search-date {
  min-width: 150px;
  max-width: 150px;
  margin-left: 5px;
}

.header-wrapper .header-inner-wrapper .search-client {
  min-width: 300px;
  max-width: 300px;
}

.header-wrapper .header-inner-wrapper .search-invoice {
  min-width: 180px;
  max-width: 200px;
}

.header-wrapper .header-inner-wrapper .search-imported {
  min-width: 180px;
  max-width: 200px;
}

.header-wrapper .status-bar {
  max-width: 800px;
}

@media screen and (max-width: 1200px) {
  .header-wrapper .header-inner-wrapper div {
    margin-right: 0;
  }

  .header-wrapper .search-date {
    min-width: 100%;
    margin-bottom: 10px;
  }

  .header-wrapper .header-inner-wrapper .search-client {
    min-width: 100%;
    margin-bottom: 10px;
  }

  .header-wrapper .header-inner-wrapper .search-invoice {
    min-width: 100%;
    margin-bottom: 10px;
  }

  .header-wrapper .header-inner-wrapper .search-imported {
    min-width: 100%;
    margin-bottom: 10px;
  }
}

.date-toggle button:nth-of-type(2) {
  border-top: 1px solid #00000061;
  border-bottom: 1px solid #00000061;
}

.header-wrapper .active-btn {
  background: #69a9e4;
}

.header-wrapper .date-wrapper {
  justify-content: center;
}

.header-wrapper .date-wrapper .add-to-date {
  width: 100%;
}

.fieldset {
  border: 1px solid #00000061;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  width: 100%;
  min-height: 70px;
  font-size: 1.2rem;
  background: #0000000f;
}

.fieldset-status {
  margin-top: -5px;
}

.legend {
  text-align: left;
  margin-left: 5px;
  padding-right: 3px;
  font-size: 13px;
  font-weight: 400;
}

.header-wrapper .date-wrapper .date-picker {
  margin-left: 40px;
  justify-content: center;
}

::v-deep .v-text-field--filled:not(.v-text-field--single-line) input {
  margin: 0 !important;
}
</style>